var render = function () {
var _obj, _obj$1, _obj$2;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{class:[
    ( _obj = {}, _obj[_vm.getModifier('has_heading')] = !!_vm.description, _obj ),
    ( _obj$1 = {}, _obj$1[_vm.getModifier('dark')] = _vm.isDark, _obj$1 ),
    ( _obj$2 = {}, _obj$2[_vm.getModifier('flush')] = _vm.isFlush, _obj$2 ),
    _vm.classnames,
    [_vm.baseClass] ]},[_vm._t("default"),_vm._v(" "),_vm._t("titleSlot",[(_vm.title)?_c('h2',[_vm._v(_vm._s(_vm.title))]):_vm._e()]),_vm._v(" "),(_vm.description)?_c('p',[_vm._v(_vm._s(_vm.description))]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }