<template>
  <header
    :class="[
      { [getModifier('has_heading')]: !!description },
      { [getModifier('dark')]: isDark },
      { [getModifier('flush')]: isFlush },
      classnames,
      [baseClass],
    ]"
  >
    <slot />
    <slot name="titleSlot">
      <h2 v-if="title">{{ title }}</h2>
    </slot>
    <p v-if="description">{{ description }}</p>
  </header>
</template>

<script>
export default {
  name: 'SectionHeading',
  props: {
    baseClass: {
      type: String,
      default: 'section_heading',
    },
    classnames: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    isDark: {
      type: Boolean,
      default: false,
    },
    isFlush: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getModifier(modifier) {
      return `${this.baseClass}--${modifier}`
    },
  },
}
</script>
